<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4 v-if="subject.year">
            Subjects of {{ subject.year.value }} Academic Year
          </h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">...</li>
          <li class="breadcrumb-item">
            <router-link
              v-if="subject.year"
              :to="{
                name: 'Academic-Years-Subjects',
                params: { year_id: subject.year.id },
              }"
            >
              Subjects
            </router-link>
          </li>
          <li class="breadcrumb-item active">View</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-md-9 col-sm-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Subject Details</h4>
          </div>
          <div class="card-body">
            <div class="basic-form">
              <div class="form-group row">
                <label
                  class="col-md-12 col-form-label"
                  style="color: rgb(120, 27, 32)"
                >
                  <i class="custom-left-border"></i> Subject
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Name: </label>
                <label class="col-md-10 col-form-label">
                  {{ subject.name }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Level: </label>
                <label v-if="subject.level" class="col-md-10 col-form-label">
                  {{ subject.level.value }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Term: </label>

                <label v-if="subject.term" class="col-md-10 col-form-label">
                  {{ subject.term.name }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Classes: </label>

                <div class="col-md-10">
                  <select
                    class="form-control"
                    name="class_id"
                    @change="filterTeachersAndGradesBasedOnClass()"
                    v-model="class_id"
                  >
                    <option value="All">All</option>
                    <option
                      v-for="row in subject.classes"
                      :key="row.id"
                      v-bind:value="row.id"
                    >
                      {{ row.name }} [Capacity: {{ row.capacity }}]
                    </option>
                  </select>
                </div>
              </div>

              <!-- Teachers -->
              <div class="form-group row">
                <label
                  class="col-md-12 col-form-label"
                  style="color: rgb(120, 27, 32)"
                >
                  <i class="custom-left-border"></i> Teachers
                </label>
              </div>

              <div class="table-responsive">
                <table
                  class="table table-bordered table-striped verticle-middle table-responsive-sm"
                >
                  <thead>
                    <tr>
                      <th scope="col">Code</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                    </tr>
                  </thead>
                  <tbody v-if="teachers.length > 0">
                    <tr v-for="teacher in teachers" :key="teacher.code">
                      <td>{{ teacher.code }}</td>
                      <td>{{ teacher.fullname }}</td>
                      <td>{{ teacher.email }}</td>
                      <td>{{ teacher.phone }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td style="text-align: center" colspan="4">
                        No Data Available.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- Students Grades -->
              <div class="form-group row">
                <label
                  class="col-md-12 col-form-label"
                  style="color: rgb(120, 27, 32)"
                >
                  <span
                    ><i class="custom-left-border"></i> Students Grades</span
                  >
                  <div class="btn-group pull-right" role="group">
                    <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Actions
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      x-placement="bottom-start"
                      style="
                        position: absolute;
                        will-change: transform;
                        top: 0px;
                        right: 0px;
                        transform: translate3d(0px, 35px, 0px);
                      "
                    >
                      <a
                        class="dropdown-item"
                        href="javascript:void()"
                        data-toggle="modal"
                        data-target="#SendGradesReport"
                        style="border-bottom: 1px solid #80808038"
                        >Send Report
                      </a>

                      <a
                        v-can="'export_grades_subjects'"
                        class="dropdown-item"
                        href="javascript:void()"
                        style="border-bottom: 1px solid #80808038"
                        @click.prevent="
                          doAction('export_students_subject_grades')
                        "
                        >Export Students
                      </a>

                      <a
                        v-can="'import_grades_subjects'"
                        class="dropdown-item"
                        href="javascript:void()"
                        data-toggle="modal"
                        data-target="#ImportStudentsGrades"
                        >Import Grades
                      </a>
                    </div>
                  </div>
                </label>
              </div>

              <div class="table-responsive">
                <table
                  class="table table-bordered table-striped verticle-middle table-responsive-sm"
                >
                  <thead>
                    <tr>
                      <th scope="col">Code</th>
                      <th scope="col">Name</th>
                      <th
                        scope="col"
                        v-for="(item, index) in subject.grade_items"
                        :key="index"
                      >
                        {{ item.name }} [Max: {{ item.max_grade }}]
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="grades.length > 0">
                    <tr v-for="grade in grades" :key="grade.id">
                      <td>{{ grade.student.user.code }}</td>
                      <td>{{ grade.student.user.fullname }}</td>
                      <td v-for="result in grade.results" :key="result.id">
                        {{ result.grade_value }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td style="text-align: center" colspan="14">
                        No Data Available.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Send report modal -->
    <div
      class="modal fade"
      id="SendGradesReport"
      tabindex="-1"
      role="dialog"
      aria-labelledby="SendGradesReportLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="SendGradesReportLabel">
              Send grades report to the parents
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form action="">
              <div class="form-group row">
                <label class="col-md-12 col-form-label"
                  >Students <span style="color: red">*</span></label
                >
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <div
                    class="form-check"
                    v-for="grade in grades"
                    :key="grade.id"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="grade.student.user.id"
                      :id="grade.id"
                      name="students[]"
                      v-model="selected_students"
                    />
                    <label class="form-check-label" :for="grade.id">
                      {{ grade.student.user.fullname }}
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              id="close"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              :disabled="disableBtn"
              class="btn btn-primary"
              @click.prevent="doAction('send_grades_report')"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Import subject grades modal -->
    <div
      v-can="'import_grades_subjects'"
      class="modal fade"
      id="ImportStudentsGrades"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ImportStudentsGradesLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ImportStudentsGradesLabel">
              Import students grades
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            @submit.prevent="submitImportStudentsGradesForm()"
            id="import_subject_grades"
          >
            <div class="modal-body">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Subject Name</label>
                <label class="col-md-8 col-form-label">
                  {{ subject.name }}
                </label>
              </div>
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Grades</label>
                <div class="col-md-8">
                  <input
                    type="file"
                    required
                    @change="handleFiles"
                    accept=".xlsx"
                    name="students_grades_file"
                    class="dropify"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger"
                id="close_import_btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                :disabled="disableBtn"
                name="submit"
                type="submit"
                class="btn btn-primary"
                value="Submit"
                onclick="this.blur();"
              >
                Import
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useSubject from "@/composables/subjects";
import { inject, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const hasPermission = inject("hasPermission");
    const swal = inject("$swal");
    const router = useRouter();
    const class_id = ref("All");
    const teachers = ref([]);
    const grades = ref([]);
    const selected_students = ref([]);
    const filtered_students = ref([]);
    const disableBtn = ref(false);
    var formDataObj = null;

    const handleFiles = async (e) => {
      formDataObj = new FormData();
      let file = e.target.files[0];
      let name = e.target.name;
      formDataObj.append(name, file);
    };

    const {
      getSubject,
      sendStudentsgradesReport,
      exportStudentsSubjectGrades,
      importantStudentsSubjectGrades,
      subject,
      errors,
    } = useSubject();

    onBeforeMount(async () => {
      //first: check the user Permission
      if (!hasPermission("view_subjects")) {
        router.push({
          name: "401",
        });
      } else {
        await getSubject(props.id);
        filterTeachersAndGradesBasedOnClass();
      }
    });

    const filterTeachersAndGradesBasedOnClass = () => {
      teachers.value = [];
      grades.value = [];
      filtered_students.value = [];
      subject.value.teachers.map(function (teacher) {
        if (teacher.class_id == class_id.value || class_id.value == "All") {
          teachers.value.push(teacher);
        }
      });

      subject.value.grades.map(function (grade) {
        if (grade.class.id == class_id.value || class_id.value == "All") {
          grades.value.push(grade);
          filtered_students.value.push(grade.student.id);
        }
      });
    };

    const doAction = async (action) => {
      swal.fire({
        title: "Please wait while submit your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      disableBtn.value = true;

      if (action == "send_grades_report") {
        await sendStudentsgradesReport(
          selected_students,
          subject.value.id
        ).then(() => {
          swal.close();
          window.scrollTo(0, 0);
          disableBtn.value = false;
        });

        document.getElementById("close").click();
        selected_students.value = [];
      }

      if (action == "export_students_subject_grades") {
        await exportStudentsSubjectGrades(
          filtered_students,
          subject.value
        ).then(() => {
          swal.close();
          window.scrollTo(0, 0);
          disableBtn.value = false;
        });
      }
    };

    const submitImportStudentsGradesForm = async () => {
      if (formDataObj == null) formDataObj = new FormData();
      const form = document.querySelector("#import_subject_grades");
      Array.from(form.elements).forEach((input) => {
        if (input.type != "file" && input.type != "submit")
          formDataObj.append(input.name, input.value);
        input.value = "";
      });

      swal.fire({
        title: "Please wait while submit your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      errors.value = [];
      disableBtn.value = true;

      formDataObj.append("subject_id", subject.value.id);
      await importantStudentsSubjectGrades(formDataObj).then((resp) => {
        swal.close();
        // router.reload();
        subject.value = resp.subject;
        filterTeachersAndGradesBasedOnClass();
        window.scrollTo(0, 0);
        disableBtn.value = false;
        document.getElementById("close_import_btn").click();
      });
      formDataObj = null;
    };

    return {
      subject,
      teachers,
      grades,
      class_id,
      disableBtn,
      selected_students,
      doAction,
      handleFiles,
      submitImportStudentsGradesForm,
      filterTeachersAndGradesBasedOnClass,
    };
  },
};
</script>
